import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
    state: {
        app_title: "Show Your Test",
        survey_form: null,
        survey_result: null,
        survey_info: null
    },
    getters: {
        appTitle: state => {
          return state.app_title;
        },
        surveyForm: state => {
          return state.survey_form;
        },
        surveyResult: state => {
          return state.survey_result;
        },
        surveyInfo: state => {
          return state.survey_info;
        },
    },
    mutations: {
        setAppTitle (state, title) {
          state.app_title = title;
        },
        setSurveyInfo (state, info) {
          state.survey_info = info;
        },
        setSurveyForm (state, form) {
          state.survey_form = form;
        },
        setSurveyResult (state, result) {
          state.survey_result = result;
        }
    },
    strict: debug
})