// import { Survey } from 'survey-vue'
import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Survey from '../views/Survey.vue'
import Complete from '../views/Complete.vue'
import Upload from '../views/Upload.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/:key',
    name: 'Home',
    component: Home
  },
  {
    path: '/:key/survey',
    name: 'Survey',
    component: Survey
  },
  {
    path: '/:key/complete',
    name: 'Complete',
    component: Complete
  },
  {
    path: '/:key/upload',
    name: 'Upload',
    component: Upload
  },
  {
    path: '/about',
    name: 'About',
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
