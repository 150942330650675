<style>
  @import '../assets/styles/upload.css';
</style>

<template>
  <v-container id="upload-page" class="background-img mb-6">
    <form>
      <v-container v-if="!uploadFilePicked">
        <v-row no-gutters class="upload-desc-row">
          <p> Your employer has requested a Covid-19 test. Please click the icon below to upload a file.</p>
        </v-row>
        <v-row no-gutters class="upload-input-row justify-center">
          <v-img
            @click="selectUpload"
            max-height="100"
            max-width="150"
            :src="require('@/assets/images/upload-btn.png')"
          ></v-img>
        </v-row>
      </v-container>
      <v-container v-if="uploadFilePicked">
        <v-row class="justify-center">
          <v-spacer></v-spacer>
          <v-col class="preview-part justify-center">
            <v-card class="preview-card" @click="selectUpload">
              <pdf v-if="uploadFileType == 'pdf'"
                  :src="previewPdfFile"
                  class="pdf-previewer"
                  @num-pages="pageCount = $event"
                  @page-loaded="currentPage = $event"
              ></pdf>
              <v-img v-if="uploadFileType == 'img'"
                class="img-previewer"
                :src="previewImgFile"
              ></v-img>
            </v-card>
            <span class="preview-replace">Replace File</span>
          </v-col>
          <v-spacer></v-spacer>
        </v-row>
        <v-row class="align-center flex-column">
          <v-radio-group row label="Choose Type:">
            <v-radio value="test" label="Test">
            </v-radio>
            <v-radio value="vaccine" label="Vaccine">
            </v-radio>
          </v-radio-group>
        </v-row>
        <v-row class="">
          <v-text-field
            label="Provider"
            placeholder="Placeholder"
            outlined
            dense
          ></v-text-field>
        </v-row>
        <v-row>
        <v-combobox
          v-model="testResult"
          :items="testItems"
          label="Test Result"
          outlined
          dense
        ></v-combobox>        
        </v-row>
        <v-row>
          <v-dialog
            ref="dialog"
            v-model="dateModal"
            :return-value.sync="administerDate"
            persistent
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="administerDate"
                label="Date Administered"
                prepend-icon="mdi-calendar"
                outlined
                dense
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="administerDate"
              scrollable
            >
              <v-spacer></v-spacer>
              <v-btn
                text
                color="primary"
                @click="dateModal = false"
              >
                Cancel
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="$refs.dialog.save(administerDate)"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-dialog>          
        </v-row>
        <v-row class="justify-center">
          <v-btn dark color="green" class="submitbtn">
            Submit
          </v-btn>
        </v-row>
      </v-container>
      <input type="file" ref="fileInput" style="display: none" @input="pickFile">
    </form>
  </v-container>
</template>

<script>
import pdf from 'vue-pdf'

export default {
  name: 'Upload',
  components: {
    pdf
  },
  data: () => ({
    testResult: "",
    testItems: ["Pass", "Fail"],
    administerDate: "",
    dateModal: false,
    uploadFilePicked: false,
    uploadFileType: "img",
    previewImgFile: "",
    previewPdfFile: "",
    menuVisible: false,
  }),
  methods: {
    selectUpload() {
      this.$refs.fileInput.click();
    },
    pickFile() {
      let input = this.$refs.fileInput
      let file = input.files
      console.log(file);
      if (file && file[0]) {
        let fileType = file[0].type;

        let reader = new FileReader
        reader.onload = e => {
          console.log(e);

          this.uploadFilePicked = true;

          if (fileType == "application/pdf") {
            this.uploadFileType = "pdf";
            this.previewPdfFile = e.target.result
          } else if (fileType == "image/jpeg") {
            this.uploadFileType = "img";
            this.previewImgFile = e.target.result
          }
        }
        reader.readAsDataURL(file[0])
        this.$emit('input', file[0])
      }
    }
  }
}
</script>