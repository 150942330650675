<template>
  <v-btn
      x-small
      outlined
      fab
      color="light-gray" 
      class="question-btn"
      @click="clickBtn"
    >
      <!-- {{ helptext }} -->
      <v-icon dark>
        mdi-help
      </v-icon>
  </v-btn>
</template>

<script>
export default {
  name: 'QuestionBtn',
  props: {
    helptext: String
  },
  methods: {
    clickBtn() {
      this.$emit('clickQuestion', this.helptext)
    }
  }
}
</script>