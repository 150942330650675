<style>
  @import '../assets/styles/complete.css';
</style>

<template>
    <v-container id="complete" class="background-img">
        <div v-if="is_passed" class="syt-success">
            <h1 class="result-title passed">Congratulations!</h1>
            <p>
                This survey was <br>
                completed by <br>
                {{ firstname }} {{ lastname }}
            </p>
            <vue-qr 
                :logoSrc="require('@/assets/logo.png')" 
                v-bind:text="qr_code_str"
                colorDark="#3a683f"
                :logoScale="0.2"
                :size="250"
            ></vue-qr>
            <p>
                You are safe to go to work
            </p>
        </div>
        <div v-else class="syt-failure">
            <h1 class="result-title warning">Warning!</h1>
            <p>
                This survey was<br> completed by <br>{{ firstname }} {{ lastname }}
            </p>
            <br>
            <p>
                You are not safe to go to work.<br>
                You will be contacted with <br>further instructions.
            </p>
        </div>
    </v-container>
</template>


<script>
import Vue from 'vue'
import VueQr from 'vue-qr'
import { mapGetters } from 'vuex'

Vue.component('vue-qr', VueQr)

export default {
  name: 'Complete',
  components: {
  },
  computed: {
    ...mapGetters(['surveyInfo','surveyResult'])
  },
  data () {
    return {
      is_passed: false,
      qr_code_str: '',
      firstname: '',
      lastname: '',
    }
  },
  mounted () {
    console.log("Complete View Mounted");
    let survey_result = this.surveyResult;
    let survey_info = this.surveyInfo;

    this.firstname = survey_info.firstname;
    this.lastname = survey_info.lastname;

    this.is_passed = survey_result.is_pass_fail;
    this.qr_code_str = survey_result.qrcode_textstring;
  }
}
</script>
