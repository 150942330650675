<style>
  @import '../assets/styles/survey.css';
</style>

<template>
  <v-container id="survey_container">
    <survey v-if="survey != null" :survey='survey'/>
    <div class="footer1"><img class="glogo" src="../assets/images/guardian.png"></div>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="290"
    >
      <v-card>
        <v-card-title class="headline">
          Help
        </v-card-title>
        <v-card-text>{{ helpDialogText }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="dialog = false"
          >
            Okay
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import Vue from 'vue';
import axios from 'axios';
import API from '../api/API.js'
import {mapGetters} from 'vuex'
import QuestionBtn from '../components/QuestionBtn'
// import VBtn from 'vuetify/lib/components/VBtn'
import * as SurveyVue from 'survey-vue'

SurveyVue.StylesManager.applyTheme("bootstrap");
SurveyVue.Serializer
    .addProperty("question", {
        name: "helptext:string",
        default: 0,
        category: "general"
    });


var Survey = SurveyVue.Survey

export default {
  name: 'surveyContainer',
  components: {
    Survey
  },
  computed: {
    ...mapGetters(['surveyForm', 'surveyInfo'])
  },  
  data () {
    return {
        survey: null,
        helpDialogText: "",
        dialog: false,
    }
  },
  mounted() {
    //Define Survey JSON
    let _this = this;
    let survey_key = this.$route.params.key;
    var surveyform = JSON.parse(JSON.stringify(this.surveyForm));
    let surveyinfo = this.surveyInfo;

    surveyform["completedHtml"] = "<h3>Thank you for completing the survey!</h3><p>Submitting the survey now. Just wait for result.</p>";
    surveyform["completeText"] = "Submit";
    
    var model = new SurveyVue.Model(surveyform);
    model.showTitle = false;

    //You may set model properties
    model
      .onComplete
      .add(function (result) {
        var data = result.data;
        var elements = surveyform.pages[0].elements;

        elements.forEach(element => {
          element.userdata = data['question'+element.question_ID];
        });

        axios
          .post(API.endpoint + API.healthsurvey, 
            {
              "clientkey": surveyinfo.clientkey,
              "contactid": surveyinfo.contactid,
              "key": survey_key,
              "contactnamecode": surveyinfo.contactnamecode,
              "surveydate": surveyinfo.surveydate,
              "annotation": surveyinfo.annotation,
              "notification_sms": surveyinfo.notification_sms,
              "notification_email": surveyinfo.notification_email,
              "debug": false,
              "surveyform": surveyform,
            },
            {
              headers: {
                'x-api-key': API.api_key
              }
            }
          )      
          .then(response => {
            console.log(response);
            if (response.status == 200) {
              var data = response.data;
              var resp = data.response;

              _this.$store.commit("setSurveyResult", resp.content.survey_results);
              _this.$router.push("/" + _this.$route.params.key + "/complete");
            }
          });
      });

    model
      .onAfterRenderQuestion
      .add(function (survey, options) {
        //Return if there is no description to show in popup
        if (!options.question.helptext) 
            return;

        const QuestionBtnClass = Vue.extend(QuestionBtn);
        //Add a button;
        var btn = new QuestionBtnClass({ propsData: { helptext: options.question.helptext }, created() {
          this.$on('clickQuestion', e => { _this.showDialog(e) })
        }});
        btn.$mount();

        var header = options
            .htmlElement
            .querySelector("h5");
        var span = document.createElement("span");
        span.innerHTML = "  ";
        header.appendChild(span);
        header.appendChild(btn.$el);
      });

    this.survey = model;
  },
  methods: {
    showDialog(msg) {
      this.helpDialogText = msg;
      this.dialog = true;
      console.log(msg);
    }
  }
}
</script>
