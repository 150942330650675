<style>
  @import '../assets/styles/home.css';
</style>

<template>
  <v-container class="home background-img">
    <v-overlay :value="isLoading">
      <v-img
        class="mx-2"
        :src="require('@/assets/logo.png')"
        max-width="100"
        contain
      ></v-img>
      <v-progress-circular
        indeterminate
        size="32"
      ></v-progress-circular>
    </v-overlay>
    <div v-if="!isLoading" >
      <div class="greeting">
        <p></p>
        <p class="username">
          This survey is for: <br> {{firstname}} {{lastname}}.
        </p>
        <br>
        <p class="tip">
          Tap Next to start your survey.
        </p>
      </div>
      <v-btn dark color="green darken-4" class="nextbtn" v-on:click="clickNext">
        Next
      </v-btn>
      <div class="footer"><img class="glogo" src="../assets/images/guardian.png"></div>
    </div>
  </v-container>
</template>

<script>
// @ is an alias to /src
// import LoadingComp from '@/components/LoadingComp.vue'
import axios from 'axios'
import API from '../api/API.js'
import { mapMutations } from 'vuex';

export default {
  name: 'Home',
  data () {
    return {
      isLoading : false,
      title: "Your Morning Health Survey",
      firstname: '',
      lastname: '',
    }
  },
  methods: {
    ...mapMutations(['setAppTitle']),
    clickNext: function () {
      this.$router.push("/" + this.$route.params.key + "/survey");
    }
  },
  mounted () {
    this.isLoading = true;

    axios
      .get(API.endpoint + API.healthsurveyrequest, {
        headers: {
          'x-api-key': API.api_key
        },
        params: { surveyrequestkey: this.$route.params.key } 
      })       
      // .get("/test.json", { params: { surveyrequestkey: this.$route.params.key } })
      .then(response => {
        console.log(response);
        var data = response.data;
        if (data.response.status == "ok") {
          var resp = data.response;
          if (resp.content.item.surveyform.title) {
            this.setAppTitle(resp.content.item.surveyform.title);
            this.title = resp.content.item.surveyform.title;
          }
          if (resp.content.item.surveyform.pages.length > 0) {
            this.firstname = resp.content.item.surveyform.pages[0].FirstName;
            this.lastname = resp.content.item.surveyform.pages[0].LastName;
          }

          var surveyform = { ...resp.content.item.surveyform};
          surveyform.pages[0].description = "* = Required"

          this.$store.commit("setSurveyForm", surveyform);

          var info = {
            "clientkey": resp.content.item.clientkey,
            "contactid": resp.content.item.contactid,
            "contactnamecode": resp.content.item.contactnamecode,
            "surveydate": resp.content.item.surveydate,
            "annotation": resp.content.item.annotation,
            "notification_sms": resp.content.item.notification_sms,
            "notification_email": resp.content.item.notification_email,
            "firstname": resp.content.item.surveyform.pages[0].FirstName,
            "lastname": resp.content.item.surveyform.pages[0].LastName,
          };
          
          this.$store.commit("setSurveyInfo", info);
        }
        this.isLoading = false;
      });
  }
}
</script>
