export default {
  api_key: "B7RAoqRTub8lOeayrXnYX5SKrmo0AVDE7EXqOZlj",
  endpoint: "https://lupfqo53qh.execute-api.us-west-2.amazonaws.com/Prod",
  healthsurveyrequest: "/healthsurveyrequest",
  healthsurvey: "/healthsurvey"

  // endpoint: "",
  // healthsurveyrequest: "/test.json",
  // healthsurvey: "/healthsurvey"
}
